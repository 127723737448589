<template>
  <div @click="setAsRead()">
    <vs-card class="overflow-hidden border-solid border-2 border-grey-light shadow-default">
      <div class="flex items-center py-6 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex-shrink-0">
            <img class="h-12 w-12 rounded-full object-cover" :src="getUserImage(application.userId) | formatPicture" alt="" />
          </div>
          <div class="min-w-0 flex-1 pl-4 pr-0 sm:px-4 md:grid md:grid-cols-2 md:gap-4">
            <div class="flex-shrink">
              <div class="flex">
                <p class="font-medium text-sm sm:text-lg text-rag-green truncate mr-3 w-40 sm:w-full md:w-3/4">{{
                  getUserName(application.userId)
                }}</p>
              </div>
              <p class="mt-2 text-sm lg:text-base text-gray-500 truncate w-40 sm:w-full md:w-3/4">
                {{ application.jobData.jobTitle }}
              </p>
            </div>
            <div class="hidden md:flex flex-col">
              <!-- <vs-chip :color="'#919191'" class="flex items-center text-sm text-white w-24">
              {{ application.status | appStatus }}
            </vs-chip> -->
              <p :class="appStatusColour()" class="p-1 text-xs leading-5 text-center font-semibold rounded-full text-white w-24">
                {{ application.status | appStatus }}
              </p>

              <p class="mt-2 flex items-center text-base text-gray-500">
                <feather-icon icon="CalendarIcon" class="text-grey text-sm mr-2" svg-classes="w-5 h-5"></feather-icon>
                <span class="truncate text-sm lg:text-base"
                  >Created on
                  {{ ' ' }}
                  <time :datetime="application.createdAt">{{ application.createdAt | applicationFormatDate }}</time></span
                >
              </p>
            </div>
          </div>

          <div :class="!applicationRead ? 'bg-red-400' : 'bg-white'" class="mr-5 py-2 px-2 rounded-full"></div>
        </div>

        <!-- <div>
        <feather-icon icon="ChevronRightIcon" class="text-gray-500" svg-classes="h-6 w-6" />
      </div> -->
      </div>
    </vs-card>
  </div>
</template>
<script>
// const ItemListView = () => import('../application-components/ItemListView');

export default {
  name: 'ApplicationUserCard',
  components: {
    // ItemListView,
  },
  props: {
    application: {
      type: Object,
      required: true,
    },
    isDeactivated: {
      type: Boolean,
    },
  },
  data() {
    return {
      applicationRead: this.application.read,
    };
  },
  computed: {
    appRead() {
      return this.application.read;
    },
    status() {
      return this.application.status;
    },
    userAccept() {
      return this.application.offerAccepted;
    },
    empReject() {
      if (this.application.employerReject !== null) {
        return this.application.employerReject;
      } else {
        return false;
      }
    },
  },
  watch: {
    application() {
      console.log('adawdawd');
    },
  },
  methods: {
    setAsRead() {
      console.log('awd');
      this.applicationRead = true;
    },
    getUserName(userId) {
      let user = this.$store.getters['userModule/getUser'][userId];
      if (user.firstName && user.firstName !== '' && user.lastName && user.lastName !== '') {
        return `${user.firstName} ${user.lastName}`;
      } else {
        return user.fullName;
      }
    },
    getUserImage(userId) {
      let user = this.$store.getters['userModule/getUser'][userId];
      return user.profileImage;
    },

    appStatusColour() {
      if (this.status === 2 || this.status === 0) {
        return 'bg-rag-green';
      }
      if (this.status === 3) {
        return 'bg-red-400';
      }
      return 'bg-dark-grey';
    },

    // handleApplication(status, reject) {
    //   if (reject) {
    //     this.application.invitationAccepted = false;
    //   }
    //   this.application.status = status;
    //   this.application.employerReject = reject;
    //   this.application.updatedAt = new Date();
    //   this.$store.dispatch('applicationsModule/patch', this.application);
    //   this.$emit('refresh');
    // },
    // deleteApplication() {
    //   this.$store.dispatch('applicationsModule/delete', this.application.id);
    //   this.$emit('refresh');
    // },
  },
};
</script>

<style lang="css">
.button-padding-app {
  padding: 6px !important;
  border-radius: 0px !important;
}
</style>
