<script>
export default {
  name: 'ApplicationsTour',
  data() {
    return {
      applicationsTourSteps: [
        {
          target: window.innerWidth > 1199 ? '.vs-sidebar-item-active' : '.navbar-custom',
          header: {
            title: '<strong>This is the Applications Tab</strong>!',
          },
          content: `Here you can view and manage the guides that have been invited or applied to your Job Posts.<br><br>From this tab, you can message guides when you open their profile, and shortlist or decline their applications.`,
          params: {
            enableScrolling: false,
            placement: 'auto',
          },
        },
      ],
      tourCallbacks: {
        onSkip: this.markTourAsSeen,
        onFinish: this.markTourAsSeen,
      },
    };
  },
  mounted() {
    const employer = this.$store.getters['employerModule/getUser'];
    if (!employer.dashboardTours || !employer.dashboardTours.applicationsTourSeen) {
      setTimeout(() => {
        try {
          this.$tours['applicationsTour'].start();
        } catch (error) {
          return;
        }
      }, 1000);
    }
  },
  methods: {
    markTourAsSeen() {
      this.$store.dispatch('employerModule/patch', { dashboardTours: { applicationsTourSeen: true } });
    },
  },
};
</script>
<template>
  <v-tour name="applicationsTour" :steps="applicationsTourSteps" :callbacks="tourCallbacks"></v-tour>
</template>

<style lang="scss">
.v-step,
.v-step__header,
.v-step__arrow,
.v-step__arrow::before {
  background: #adca63 !important;
}
.v-step {
  border-radius: 10px !important;
  z-index: 99999 !important;
}
.v-step__header {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  margin-top: 0 !important;
}

.v-step__button {
  border-radius: 5px !important;
  border-top-width: 0.1rem !important;
  font-size: 1rem !important;
}
</style>
